<template>
	<div id="landOrderDetail">
		<page-land-rent-order-detail :is-shop="0"></page-land-rent-order-detail>
	</div>
</template>

<script>
import pageLandRentOrderDetail from '@/components/layout/land/page-land-rent-order-detail.vue'
import store from '@/store'
import { onBeforeRouteLeave } from 'vue-router'
export default{
	components:{
		pageLandRentOrderDetail
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/land/land_order'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})
	}
}
</script>

<style>
</style>
